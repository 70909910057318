<script setup lang="ts">
import { computed, defineProps, PropType } from "vue";
import { Document } from "@/types";
import Ok from "@/components/general/Ok.vue";

const props = defineProps({
    document: {
        type: Object as PropType<Document>,
        required: true,
    },
});

const daysLate = computed(() => {
    const maxDays = 28; // todo get from company settings
    const today = new Date().getTime();
    const invoiceDate = new Date(
        props.document.year +
            "-" +
            props.document.month +
            "-" +
            props.document.day
    ).getTime();
    const difference = today - invoiceDate;
    const msPerDay = 1000 * 60 * 60 * 24;
    return Math.round(difference / msPerDay - maxDays);
});
</script>

<template>
    <div
        class="PaidStatus"
        :data-paid="document.paid"
        :class="{ 'PaidStatus--alert': daysLate > 28 && !document.paid }"
    >
        <ok v-if="document.paid" />
        <template v-else>
            {{ daysLate }}
        </template>
    </div>
</template>

<style lang="scss" scoped>
.PaidStatus {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    font-weight: 700;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
    background: #fff;

    &[data-paid="false"] {
        background: orange;
        color: #fff;
    }

    &--alert {
        background: red;
        color: #fff;
    }
}
</style>
