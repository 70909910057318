<script setup lang="ts">
import { computed, defineProps, PropType } from "vue";
import { Document } from "@/types";
import InnForm from "@/components/ds/form/Form.vue";
import DocumentDelete from "@/components/pages/document/tools/DocumentDelete.vue";
import Tools from "@/components/general/tools/Tools.vue";
import Tool from "@/components/general/tools/Tool.vue";
import DocumentAdministration from "@/components/pages/document/tools/DocumentAdministration.vue";
import DocumentOpen from "@/components/pages/document/tools/DocumentOpen.vue";
import { useCompanyStore } from "@/stores/company";
import DocumentMail from "@/components/pages/document/tools/DocumentMail.vue";

defineProps({
    document: {
        type: Object as PropType<Document>,
        required: true,
    },
    owner: {
        type: Boolean,
        default: true,
    },
});

const companyStore = useCompanyStore();

const config1 = {
    sections: [
        {
            items: [
                {
                    type: "checkbox",
                    key: "english",
                    label: "Engelstalig",
                },
                {
                    type: "checkbox",
                    key: "hideTotal",
                    label: "Verberg totaal",
                },
                {
                    type: "number",
                    key: "rate",
                    label: "Tarief",
                },
                {
                    type: "text",
                    key: "currency",
                    label: "Valuta",
                },
                {
                    type: "number",
                    key: "vat",
                    label: "BTW",
                },
            ],
        },
    ],
};

const config2 = computed(() => {
    const drive = {
        type: "text",
        key: "google_drive_id",
        label: "Google Drive ID",
    };
    const acumulus = {
        type: "number",
        key: "acumulus_invoice_id",
        label: "Acumulus ID",
    };
    const c = {
        sections: [
            {
                items: [
                    {
                        type: "checkbox",
                        key: "paid",
                        label: "Betaald",
                    },
                ],
            },
            {
                items: [
                    {
                        type: "checkbox",
                        key: "locked",
                        label: "Lock (niet aan zitten, dit kan SSOT aantasten)",
                    },
                ],
            },
        ],
    };
    if (companyStore.company?.usesGoogleDrive) {
        c.sections[c.sections.length - 1].items.push(drive);
    }
    if (companyStore.company?.usesAcumulus) {
        c.sections[c.sections.length - 1].items.push(acumulus);
    }
    return c;
});
</script>

<template>
    <div class="DocumentTools">
        <tools>
            <tool
                v-if="
                    owner &&
                    (companyStore.company?.usesAcumulus ||
                        companyStore.company?.usesGoogleDrive)
                "
            >
                <document-administration :document="document" />
            </tool>

            <tool v-if="document.google_drive_id !== ''">
                <document-open :document="document" />
            </tool>

            <tool
                v-if="
                    owner &&
                    companyStore.company?.usesMail &&
                    document.doctype === 'invoice' &&
                    document.google_drive_id !== ''
                "
            >
                <document-mail :document="document" />
            </tool>
        </tools>

        <inn-form v-if="!document.locked" :config="config1" :value="document" />

        <inn-form :config="config2" :value="document" />

        <tools v-if="owner">
            <tool align="right">
                <document-delete :document="document" />
            </tool>
        </tools>
    </div>
</template>

<style lang="scss" scoped>
.DocumentTools {
    height: 100%;
    overflow: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__tools {
        display: flex;
        gap: 12px;
        flex-direction: column;
    }
}
</style>
